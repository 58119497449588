/* eslint-disable @next/next/no-img-element */

import esLocale from 'date-fns/locale/es';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { FC, useEffect } from 'react';

import { AppProvider } from '~/AppProvider';
import { useLeadQuery } from '~/hooks/api/useLeadQuery';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useLeadMutation } from '~/hooks/useLeadMutation';
import { useTracking } from '~/hooks/useTracking';

import { config } from '../config';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import i18nextConfig from '../next-i18next.config';

const SEO_PAGES = ['/', '/reviews'];
const EXCLUDE_PIXEL_PAGES = [
  '/upsells/annual-upgrade',
  '/create-account',
  '/success',
  '/upsells/workouts',
  '/upsells/stress-release',
  '/upsells/meditations',
  '/upsells/bundle',
];

const DATESET_ID_MAP: Record<string, string> = {
  trial: '2207253949708929',
  report: '605797599079117',
};

const MyApp = ({ Component, pageProps }: AppProps) => {
  const AppComponent = Component as unknown as FC;
  const { asPathname, isLocaleDefault, funnelName } = useAppRouter();
  const dataSourceId = DATESET_ID_MAP[funnelName as keyof typeof DATESET_ID_MAP] || '245623751428036';

  // TODO: add other locales
  useEffect(() => {
    if (pageProps.locale === 'es-ES') {
      setDefaultOptions({ locale: esLocale });
    }
  }, [pageProps.locale]);

  return (
    <>
      {config.IS_PROD && !EXCLUDE_PIXEL_PAGES.includes(asPathname) ? (
        <>
          <script
            id='metaPixel'
            dangerouslySetInnerHTML={{
              __html: `!function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', '${dataSourceId}');
                  fbq('track', 'PageView');`,
            }}
          ></script>
          <noscript>
            <img
              height='1'
              width='1'
              style={{ display: 'none' }}
              src={`https://www.facebook.com/tr?id=${dataSourceId}&ev=PageView&noscript=1`}
              alt=''
            />
          </noscript>
        </>
      ) : null}
      <AppProvider>
        <AppComponent {...pageProps} />
        <VisitorTracking />
      </AppProvider>
      <Head>
        {SEO_PAGES.includes(asPathname) ? (
          <>{isLocaleDefault ? null : <link rel='canonical' href={`https://www.${config.domain}${asPathname}`} />}</>
        ) : (
          <meta name='robots' content='noindex' />
        )}
        <title>{`Lose weight and say goodbye to bloating, constipation, and digestion issues | ${config.name}`}</title>
        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' />
      </Head>
    </>
  );
};

const VisitorTracking = () => {
  const { id } = useAppLead();
  const { query } = useAppRouter();
  const { isCheckoutPage, isPlansOrCommitPage } = useAppRouter();
  const tracking = useTracking();
  const leadMutation = useLeadMutation();
  const leadId = query.leadCode as string;

  useLeadQuery(leadId);

  useEffect(() => {
    if (isCheckoutPage) {
      tracking.checkoutVisit();
    }

    if (isCheckoutPage && id) {
      leadMutation.mutate({ id, customFields: { initiatedCheckout: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckoutPage]);

  useEffect(() => {
    if (isPlansOrCommitPage) {
      tracking.plansVisit();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlansOrCommitPage]);

  useEffect(() => {
    tracking.sessionInit();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default appWithTranslation(MyApp, i18nextConfig);
